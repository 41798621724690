export default {
  proxy_path: "https://5tg37e2jaa.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://22djz2k2ni.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyA5Ofaf2rUJ4Qp9Xo1YVA2rDP4-f0PQ3Es",
  main_company_term_id: "6",
  crm_base_url: "https://crm.staging.emeco.forwoodsafety.com",
  forwood_id_url: "https://id.staging.emeco.forwoodsafety.com?redirect_uri=https://mapreport.staging.emeco.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.emeco.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_c4DHRoJM0",
    userPoolWebClientId: "5nt9o54g5pjgt4c1v9htsek8ph",
    cookieStorage: {
      domain: ".staging.emeco.forwoodsafety.com",
      secure: true
    }
  }
};
